import React, { useState, useEffect } from 'react';
import styles from './Compass.module.css';
import HomeIcon from "@material-ui/icons/Home"; 
import { CesiumViewerWrapper } from '../CesiumViewerWrapper/CesiumViewerWrapper';
interface ICompassProps {
    cesiumViewerWrapper: CesiumViewerWrapper;
    viewerRotation: number;
}
const Compass = (props: ICompassProps) => {
    let Cesium = (window as any).Cesium;
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        setRotation(props.viewerRotation);
    }, [props.viewerRotation]);

    const onDirectionClick = (direction: any) => {
        console.log("Clicked direction:", direction);

        // Set the default view to point north
        props.cesiumViewerWrapper.camera.setView({
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
            }
        });

        let destination = getMapCenter();
  
        switch (direction) {
            case "N":
                props.cesiumViewerWrapper.camera.flyTo({
                    destination: destination,
                    orientation: {
                        heading: Cesium.Math.toRadians(0.0)
                    }
                });
                break;
            case "E":
                props.cesiumViewerWrapper.camera.flyTo({
                    destination: destination,
                    orientation: {
                        heading: Cesium.Math.toRadians(90.0)
                    }
                });
                break;
            case "S":
                props.cesiumViewerWrapper.camera.flyTo({
                    destination: destination,
                    orientation: {
                        heading: Cesium.Math.toRadians(180.0)
                    }
                });
                break;
            case "W":
                props.cesiumViewerWrapper.camera.flyTo({
                    destination: destination,
                    orientation: {
                        heading: Cesium.Math.toRadians(240.0)
                    }
                });
                break;
        }
    };

    function getMapCenter(): any {
        var windowPosition = new Cesium.Cartesian2(props.cesiumViewerWrapper.container.clientWidth / 2, props.cesiumViewerWrapper.container.clientHeight / 2);
        var pickRay = props.cesiumViewerWrapper.scene.camera.getPickRay(windowPosition);
        var pickPosition = props.cesiumViewerWrapper.scene.globe.pick(pickRay, props.cesiumViewerWrapper.scene);
        var pickPositionCartographic = props.cesiumViewerWrapper.scene.globe.ellipsoid.cartesianToCartographic(pickPosition);

        let destination = Cesium.Cartesian3.fromRadians(
            pickPositionCartographic.longitude,
            pickPositionCartographic.latitude,
            props.cesiumViewerWrapper.camera.positionCartographic.height
        );

        return destination;
    }

    const onHomeClick = () => {
        // Set the default view to point north
        props.cesiumViewerWrapper.camera.setView({
            orientation: {
                heading: Cesium.Math.toRadians(0.0), 
            }
        });

        let destination = getMapCenter();
        props.cesiumViewerWrapper.camera.flyTo({
            destination: destination,
            orientation: {
                heading: Cesium.Math.toRadians(0.0)
            }
        });
    };

   
    return (
        <div className={styles.compassContainer} style={{ transform: `rotate(${rotation}deg)` }}>
            <div className={styles.compassDirection} style={{ height:'10px' }}  onClick={() => onDirectionClick("N")}>N
                <div className={styles.arrow} />
            </div>
            <div className={styles.compassDirection} onClick={() => onDirectionClick("E")}>E</div>
            <div className={styles.compassDirection} onClick={() => onDirectionClick("S")}>S</div>
            <div className={styles.compassDirection} onClick={() => onDirectionClick("W")}>W</div>
            <div className={styles.compassHome} onClick={onHomeClick}>
                <HomeIcon fontSize="inherit"/>
            </div>
        </div>
    );
};

export default Compass;

