
// This class wraps the Cesium viewer.  Its needed because we have optimised the performance of the Cesium viewer to redraw as little as possible
// and this means that when we Add or Remove primitives and entities we need to manually call the requestRender() function or those changes
// might not be shown.

export class CesiumViewerWrapper {
    cesiumViewer: any = null;

    setCesiumViewer(cesiumViewer:any)
    {
        this.cesiumViewer = cesiumViewer;
    }

    hasViewer():boolean {
        return this.cesiumViewer != null;
    } 

    addPrimitiveToScene(primitive:any):any
    {
        if (this.cesiumViewer){
            let addedPrimitive = this.cesiumViewer.scene.primitives.add(primitive);
            this.cesiumViewer.scene.requestRender();

            return addedPrimitive;
        }
        else 
        {
            return null;
        }
    }

    removePrimitiveFromScene(primitive:any)
    {
        if (this.cesiumViewer){
            this.cesiumViewer.scene.primitives.remove(primitive);
            this.cesiumViewer.scene.requestRender();
        }
    }

    addEntity(entity:any):any
    {
        if (this.cesiumViewer){
            let addedEntity = this.cesiumViewer.entities.add(entity);
            this.cesiumViewer.scene.requestRender();

            return addedEntity;
        }
        else 
        {
            return null;
        }
    }

    removeEntity(entity:any)
    {
        if (this.cesiumViewer){
            this.cesiumViewer.entities.remove(entity);
            this.cesiumViewer.scene.requestRender();
        }
    }

    get terrainProvider():any {
        return this.cesiumViewer?.terrainProvider;
    } 

    set terrainProvider(terrainProvider:any) {
        if (this.cesiumViewer ) 
            this.cesiumViewer.terrainProvider = terrainProvider;
    } 
    
    get scene():any {
        return this.cesiumViewer?.scene;
    } 

    get camera():any {
        return this.cesiumViewer?.camera;
    } 

    get container(): any {
        return this.cesiumViewer?.container;
    } 

    destroyViewer(){
        if (this.cesiumViewer){
            this.cesiumViewer.destroy();
            this.cesiumViewer = null;
        }
    }

    zoomTo(entity:any, headingPitchRange:any )
    {
        if (this.cesiumViewer)
            this.cesiumViewer.zoomTo(entity, headingPitchRange);
    }
}